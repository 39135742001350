<template>
	<div class="page">
		<div class="option-box">
			<Icon type="ios-redo" @click="back" />
			<Form :model="formItem" inline>
				<FormItem label="省市">
					<Select v-model="formItem.sheng" style="width: 100px; margin-right: 10px" size="small"
						placeholder="省份" clearable filterable @on-change="selectProvince">
						<Option v-for="item in landProvinceList" :value="item.areaId" :key="item.areaId">{{ item.name }}
						</Option>
					</Select>
					<Select v-model="formItem.cs" style="width: 100px" size="small" placeholder="城市" clearable
						filterable>
						<Option v-for="item in cityList" :value="item.areaId" :key="item.areaId">{{ item.name }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="小区名称">
					<Input clearable filterable type="text" v-model="formItem.xqmc" placeholder="小区名称" />
				</FormItem>
				<FormItem label="证载地址">
					<Input clearable filterable type="text" v-model="formItem.zzdz" placeholder="证载地址" />
				</FormItem>
			</Form>
			<Form :model="formItem" inline>
				<FormItem label="任务名称">
					<Input clearable filterable type="text" v-model="formItem.taskName" placeholder="任务名称" />
				</FormItem>
				<FormItem label="风险预警">
					<Select v-model="formItem.yjFlag" clearable filterable>
						<Option v-for="(item, index) in optionList.yjFlag" :key="index" :value="item.id">{{ item.name }}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="总价">
					<Input type="number" v-model="formItem.cgzjStart" style="width: 120px"
						@on-change="changeRangeVal($event.target.value, 'cgzj', 0)" placeholder="估价总价" />
					<span style="margin: 0 10px">至</span>
					<Input type="number" v-model="formItem.cgzjEnd" style="width: 120px"
						@on-change="changeRangeVal($event.target.value, 'cgzj', 1)" placeholder="估价总价" />万元
				</FormItem>
			</Form>
			<Form :model="formItem" inline>
				<FormItem label="最高可用担保额度">
					<Input type="number" v-model="formItem.zgkydbedStart" style="width: 120px"
						@on-change="changeRangeVal($event.target.value, 'zgkydbed', 0)" placeholder="最高可用担保额度" />
					<span style="margin: 0 10px">至</span>
					<Input type="number" v-model="formItem.zgkydbedEnd" style="width: 120px"
						@on-change="changeRangeVal($event.target.value, 'zgkydbed', 1)" placeholder="最高可用担保额度" />万元
				</FormItem>
				<FormItem label="租金">
					<Input type="number" v-model="formItem.zjStart" style="width: 96px"
						@on-change="changeRangeVal($event.target.value, 'zj', 0)" placeholder="租金" />
					<span style="margin: 0 10px">至</span>
					<Input type="number" v-model="formItem.zjEnd" style="width: 96px"
						@on-change="changeRangeVal($event.target.value, 'zj', 1)" placeholder="租金" />元/月
				</FormItem>
			</Form>
			<Form :model="formItem" inline>
				<FormItem label="提交时间">
					<DatePicker v-model="formItem.tjsjStart" type="date" placeholder="开始时间" format="yyyy-MM-dd"
						@on-change="changeRangeVal($event, 'tjsj', 0)" style="width: 120px"></DatePicker>
					<span class="split">至</span>
					<DatePicker v-model="formItem.tjsjEnd" type="date" placeholder="结束时间" format="yyyy-MM-dd"
						@on-change="changeRangeVal($event, 'tjsj', 1)" style="width: 120px"></DatePicker>
				</FormItem>
				<FormItem label="初始估价日">
					<DatePicker v-model="formItem.cspgrStart" type="date" placeholder="开始时间" format="yyyy-MM-dd"
						@on-change="changeRangeVal($event, 'cspgr', 0)" style="width: 120px"></DatePicker>
					<span class="split">至</span>
					<DatePicker v-model="formItem.cspgrEnd" type="date" placeholder="结束时间" format="yyyy-MM-dd"
						@on-change="changeRangeVal($event, 'cspgr', 1)" style="width: 120px"></DatePicker>
				</FormItem>
				<FormItem label="最新估价基准日">
					<DatePicker v-model="formItem.zxpgjzrStart" type="date" placeholder="开始时间" format="yyyy-MM-dd"
						@on-change="changeRangeVal($event, 'zxpgjzr', 0)" style="width: 120px"></DatePicker>
					<span class="split">至</span>
					<DatePicker v-model="formItem.zxpgjzrEnd" type="date" placeholder="结束时间" format="yyyy-MM-dd"
						@on-change="changeRangeVal($event, 'zxpgjzr', 1)" style="width: 120px"></DatePicker>
				</FormItem>
			</Form>
			<Form inline>
				<FormItem label="建筑面积">
					<RadioGroup v-model="formItem.mj">
						<Radio :label="item.id" v-for="(item, index) in optionList.mj" :key="index">{{ item.name }}
						</Radio>
					</RadioGroup>
					<!-- <CheckboxGroupAll v-model="formItem.mj" @change-all="(data) => this.checkboxGroupChange(formItem,'mj',data)" :list="optionList.mj" :checkboxList="optionList.mj">
          </CheckboxGroupAll>-->
				</FormItem>
			</Form>
			<div class="btn-box">
				<div class="btn search searchBtnLog" data-id="1" @click="search">查询</div>
			</div>
		</div>

		<div class="choose-box">
			<TagGroup :tagList="selectList" @close="delSingle"></TagGroup>
		</div>
		<div class="downBox">
			<DownloadBtn :id="1" :type=1 @exportData="exportData('all')" :title="btnText1"
				:style="{ 'background-color': 'rgba(241, 110, 113, 1)' }"></DownloadBtn>
			<DownloadBtn :id="1" :type=1 @exportData="exportData('some')" :title="btnText2" :style="{
          position: 'absolute',
          left: '90px',
          top: '0px',
          'background-color': '#00b6ff',
        }"></DownloadBtn>
		</div>

		<div class="table-box">
			<Table :columns="tableColumns" :data="tableData" border tooltip-theme="light" height="560" class="table"
				:loading="loading" highlight-row @on-selection-change="selectTableData"></Table>
			<Page ref="page" :total="total" show-elevator show-sizer show-total :page-size-opts="sizeOpts"
				@on-page-size-change="pageSizeChange" @on-change="pageChange" />
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	// @ is an alias to /src
	import formMixin from "@/mixins/form";
	import {
		ypinfo,
		mj
	} from "@/api/pledge";
	import {
		cityArea,
		cityPro
	} from "@/api/city";
	import {
		getLandSingleSelectCity
	} from "@/api/public";
	import DownloadBtn from "@/components/DownloadBtn";
	import {
		downloadData,
		formatJson
	} from "@/utils/index";
	import dataReports from "@/mixins/dataReports";

	export default {
		name: "pledgeSearch",
		mixins: [formMixin, dataReports],
		components: {
			DownloadBtn,
		},
		data() {
			return {
				sizeOpts: [10, 20, 30, 40],
				btnText1: "全表下载",
				btnText2: "选择下载",
				mjList: [],
				cityList: [],
				selectedTableData: [],
				selectionOption: [{
						name: "省份",
						key: "sheng",
						type: "option",
					},
					{
						name: "城市",
						key: "cs",
						type: "option",
					},
					{
						name: "小区名称",
						key: "xqmc",
						type: "string",
					},
					{
						name: "证载地址",
						key: "zzdz",
						type: "string",
					},
					{
						name: "任务名称",
						key: "taskName",
						type: "string",
					},
					{
						name: "风险预警",
						key: "yjFlag",
						type: "option",
					},
					{
						name: "建筑面积",
						key: "mj",
						type: "option",
					},
					{
						name: "重估总价",
						key: "cgzj",
						type: "range",
					},
					{
						name: "最高可用担保额度",
						key: "zgkydbed",
						type: "range",
					},
					{
						name: "租金",
						key: "zj",
						type: "range",
					},
					{
						name: "提交时间",
						key: "tjsj",
						type: "range",
					},
					{
						name: "初始估价日",
						key: "cspgr",
						type: "range",
					},
					{
						name: "最新估价基准日",
						key: "zxpgjzr",
						type: "range",
					},
				],
				formItem: {
					cgzj: [],
					sheng: "",
					cs: "",
					cspgr: [],
					current: 1,
					mj: "",
					size: 10,
					taskName: "",
					tjsj: [],
					xqmc: "",
					yjFlag: "",
					zgkydbed: [],
					zj: [],
					zxpgjzr: [],
					zzdz: "",
				},

				loading: false,
				total: 0,
				tableData: [],
				tableColumns: [{
						type: "selection",
						width: 60,
						align: "center",
						fixed: "left",
					},
					{
						title: "序号",
						key: "id",
						align: "center",
						width: 80,
						render: (h, params) => {
							let page = this.$refs["page"];
							return h(
								"div",
								page.currentPageSize * (page.currentPage - 1) + (params.index + 1)
							);
						},
					},
					{
						title: "任务名称",
						key: "taskName",
						align: "center",
						width: 140,
					},
					{
						title: "估价对象编号",
						key: "ypId",
						align: "center",
						width: 140,
						sortable: true,
					},
					{
						title: "城市",
						key: "cs",
						align: "center",
						width: 140,
					},
					{
						title: "证载地址",
						key: "zzdz",
						align: "center",
						width: 300,
						ellipsis: true,
						render: (h, params) => {
							return h(
								"div", {
									attrs: {
										class: "btn-detail",
										title: params.row.zzdz,
									},
									on: {
										click: () => this.gotoSingle(params),
									},
								},
								params.row.zzdz
							);
						},
					},
					{
						title: "小区名称",
						key: "xqmc",
						align: "center",
						width: 200,
						ellipsis: true,
						render: (h, params) => {
							return h(
								"div", {
									attrs: {
										class: "btn-detail",
										title: params.row.xqmc,
									},
									on: {
										click: () => this.gotoDetail(params),
									},
								},
								params.row.xqmc
							);
						},
					},
					{
						title: "估价基准日",
						key: "initialEvalDate",
						align: "center",
						width: 140,
					},
					{
						title: "建筑面积（㎡）",
						key: "jzmj",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "建筑面积"), h("div", "（㎡）")]);
						},
					},
					{
						title: "单价（元/㎡）",
						key: "pgdj",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "单价"), h("div", "（元/㎡）")]);
						},
					},
					{
						title: "总价(万元)",
						key: "pgzj",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "总价"), h("div", "(万元)")]);
						},
						render: (h, params) => {
							return h(
								"div",
								params.row.pgzj == "" ? "" : +(params.row.pgzj / 10000).toFixed(2)
							);
						},
					},
					{
						title: "调整后单价（元/㎡）",
						key: "fjxzhzDj",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [
								h("div", "调整后单价"),
								h("div", "（元/㎡）"),
							]);
						},
					},
					{
						title: "调整后总价(万元)",
						key: "fjxzhzZj",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "调整后总价"), h("div", "(万元)")]);
						},
						render: (h, params) => {
							return h(
								"div",
								params.row.fjxzhzZj == "" ?
								"" :
								+(params.row.fjxzhzZj / 10000).toFixed(2)
							);
						},
					},
					{
						title: "预警值（万元）",
						key: "yjz",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "预警值"), h("div", "（万元）")]);
						},
						render: (h, params) => {
							if (params.row.yjz) {
								var yjLevelList = ["blue", "orange", "yellow", "red"];
								return h(
									"div", {
										attrs: {
											class: "item-warn " + yjLevelList[params.row.yjLevel],
										},
									},
									[
										h(
											"div",
											params.row.yjz == "" ?
											"" :
											+(params.row.yjz / 10000).toFixed(2)
										),
										h("span", ""),
									]
								);
							} else {
								return h(
									"div",
									params.row.yjz == "" ? "" : +(params.row.yjz / 10000).toFixed(2)
								);
							}
						},
					},
					{
						title: "最高抵质押率(%)",
						key: "zgdzyl",
						align: "center",
						width: 140,
					},
					{
						title: "最高可用担保额度（万元）",
						key: "zgkdbed",
						align: "center",
						width: 160,
						renderHeader: (h, index) => {
							return h("div", [h("div", "最高可用担保额度"), h("div", "(万元)")]);
						},
						render: (h, params) => {
							return h(
								"div",
								params.row.zgkdbed ? +(params.row.zgkdbed / 10000).toFixed(2) : 0
							);
						},
					},
					{
						title: "租金价格（元/㎡/月）",
						key: "zjdjpg",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "租金价格"), h("div", "（元/㎡/月）")]);
						},
					},
					{
						title: "租金（元/月）",
						key: "zjzjpg",
						align: "center",
						width: 140,
						renderHeader: (h, index) => {
							return h("div", [h("div", "租金"), h("div", "（元/月）")]);
						},
					},
				],
				pageSize: 10,
				pageIndex: 1,
				landProvinceList: [],

				chooseRangeOption: {
					zj: "租金",
					cgzj: "估价总价",
					zgkydbed: "最高可用担保额度",
				},
			};
		},
		computed: {
			...mapState([
				//"landProvinceList",
			]),
			optionList() {
				return {
					sheng: this.landProvinceList,
					cs: this.cityList,
					mj: this.mjList,
					yjFlag: [{
							id: 0,
							name: "正常",
						},
						{
							id: 1,
							name: "橙色",
						},
						{
							id: 2,
							name: "黄色",
						},
						{
							id: 3,
							name: "红色",
						},
					],
				};
			},
		},
		mounted() {


			this.initData();
			this.getList();
		},
		methods: {
			_Pro() {
				cityArea().then((res) => {
					res.forEach((item) => {
						if (item.name != "全国") {
							this.landProvinceList.push(item);
						}
					});
				});
			},
			exportData(type) {
				//使用type区分下载全部数据还是选择的数据
				let params = this.getSearchData();
				params.current = 1;
				params.size = 999999;
				//使用ypinfo这个接口来下载所有的数据
				ypinfo(params).then((res) => {
					let filterVal = [];
					let tHeader = [];
					this.tableColumns.forEach((item) => {
						tHeader.push(item.title);
						filterVal.push(item.key);
					});
					tHeader.shift(0, 1);
					filterVal.shift(0, 1);
					const data = formatJson(
						filterVal,
						//当type为all时，下载全部的数据
						type == "all" ?
						res.records.map((item, index) => {
							item.id = index + 1;
							item.yjz && (item.yjz = +(item.yjz / 10000).toFixed(2));
							item.pgzj && (item.pgzj = +(item.pgzj / 10000).toFixed(2));
							item.zgkdbed &&
								(item.zgkdbed = +(item.zgkdbed / 10000).toFixed(2));
							item.fjxzhzZj &&
								(item.fjxzhzZj = +(item.fjxzhzZj / 10000).toFixed(2));
							return item;
						}) :
						this.selectedTableData.map((item, index) => {
							item.id = index + 1;
							item.yjz && (item.yjz = +(item.yjz / 10000).toFixed(2));
							item.pgzj && (item.pgzj = +(item.pgzj / 10000).toFixed(2));
							item.zgkdbed &&
								(item.zgkdbed = +(item.zgkdbed / 10000).toFixed(2));
							item.fjxzhzZj &&
								(item.fjxzhzZj = +(item.fjxzhzZj / 10000).toFixed(2));
							return item;
						})
					);
					if (data.length == 0) {
						this.$msg.error({
							text: "请勾选需要下载的数据"
						});

						return;
					}
					//调用downloadData这个方法来下载数据
					downloadData(tHeader, data, "估价");
				});
			},
			selectTableData(e) {
				this.selectedTableData = e;
			},
			back() {
				this.$router.push({
					path: "/Empowerment/pledge/pledgeManage/pledgeManage",
				});
			},
			initData() {
				mj().then((res) => {
					res.unshift({
						id: "",
						name: "全部",
					});
					this.mjList = res;
				});
				this._Pro();
			},
			selectProvince(item) {
				cityPro({
					pid: item,
				}).then((data) => {
					this.cityList = data;
					this.formItem.cs = "";
					// this.getList();
				});
			},

			getSearchData() {
				let params = Object.assign({}, this.formItem);

				for (let key in this.chooseRangeOption) {
					let val = params[key];
					if (val.length && (!val[0] || !val[1])) {
						delete params[key];
					}
				}

				return params;
			},
			search() {
				this.formItem.current = 1;
				this.getList();
			},
			add() {
				this.$router.push({
					path: "/Empowerment/pledge/pledgeManage/taskAdd",
				});
			},

			getList() {
				let params = this.getSearchData();

				ypinfo(params).then((res) => {
					this.total = res.total;
					this.sizeOpts = [10, 20, 30, 40, res.total];
					this.tableData = res.records;
				});
			},
			gotoSingle(params) {
				this.$router.push({
					path: "/Empowerment/pledge/pledgeManage/result",
					query: {
						zzdz: params.row.zzdz,
						taskId: params.row.taskId,
						resultId: params.row.id,
					},
				});
			},
			gotoDetail(params) {
				this.$router.push({
					path: "/Empowerment/pledge/pledgeManage/regionResult",
					query: {
						cs: params.row.cs,
						csId: params.row.csId,
						xqmc: params.row.xqmc,
						xqId: params.row.xqId,
						evalDate: params.row.initialEvalDate
					},
				});
			},

			pageSizeChange(size) {
				this.formItem.current = 1;
				this.formItem.size = size;
				this.getList();
			},
			pageChange(size) {
				this.formItem.current = size;
				this.getList();
			},
			formatDate: function(val) {
				const value = new Date(val);
				const year = value.getFullYear();
				const month = value.getMonth() + 1;
				const day = value.getDate();
				// const hour=(value.getHours());
				// const minutes=(value.getMinutes());
				// const seconds=(value.getSeconds());
				return (
					year +
					"-" +
					(month >= 10 ? month : "0" + month) +
					"-" +
					(day >= 10 ? day : "0" + day)
				);
			},
		},
	};
</script>


<style lang="scss" scoped>
	@import "@/style/common.scss";

	.page {
		@include flex(flex-start, flex-start);
		flex-direction: column;
		height: 100%;
		min-width: 1200px;
		padding: 20px;

		.ivu-icon-ios-redo {
			position: absolute;
			top: 20px;
			right: 20px;
			color: #00b6ff;
			font-size: 36px;
			transform: rotateY(180deg);
			cursor: pointer;
		}

		.option-box {
			position: relative;
			width: 100%;
			margin-bottom: 20px;
			padding: 20px 30px;
			border-radius: 10px;
			border: 1px solid #e3e3e5;

			/deep/ .ivu-form-item {
				margin-right: 40px;

				.ivu-form-item-content {
					display: inline-block;
				}
			}

			.split {
				margin: 0 15px;
			}

			.btn-box {
				@include flex;
				margin: 20px 0 0;

				.btn {
					@include flex;
					width: 144px;
					height: 40px;
					color: #fff;
					font-size: 18px;
					border-radius: 5px;
					cursor: pointer;

					&.search {
						margin-right: 40px;
						background-color: #00b6ff;
					}

					&.add {
						background-color: #1562d6;
					}
				}
			}
		}

		.choose-box {
			@include flex(flex-start);
			width: 100%;
			margin-bottom: 20px;
		}

		.downBox {
			position: relative;
			width: 100%;
			height: 50px;
			margin-bottom: 20px;
		}

		.table-box {
			width: 100%;
			border: none;
			position: relative;

			/deep/ .btn-detail {
				@include ellipsis;
				color: #00b6ff;
				cursor: pointer;
			}

			.ivu-page {
				margin-top: 18px;
				text-align: right;
			}

			/deep/ .ivu-table-border td {
				position: relative;
			}

			/deep/ .item-warn {
				@include flex;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				line-height: 1;
				// > span {
				// 	@include bgSrc('pledge/blue.png');
				// 	display: block;
				// 	width: 15px;
				// 	height: 15px;
				// 	margin-left: 5px;
				// }

				&.orange {
					color: #333;
					background-color: orange;

					>span {
						@include bgSrc("pledge/orange.png");
					}
				}

				&.yellow {
					color: #333;
					background-color: yellow;

					>span {
						@include bgSrc("pledge/yellow.png");
					}
				}

				&.red {
					color: #333;
					background-color: red;

					>span {
						@include bgSrc("pledge/red.png");
					}
				}
			}
		}

		.downBtn1 {
			position: absolute;
		}
	}
</style>
